import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { Checklist } from '../components/checklist';
import { ContactSection } from '../components/contact';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { ServiceJumpTable } from '../components/servicejumptable';
import { makeSectionId } from '../components/jumpboxlink';
import Seo from '../components/seo';

function VulnerabilityManagement({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t('audit_title', 'Security Audit Management'),
            desc: t(
                'audit_desc',
                'Auditplanung und -Nachhaltung - jederzeit im Blick'
            ),
        },
        {
            title: t(
                'vulnerability_title',
                'Security Vulnerability Management'
            ),
            desc: t(
                'vulnerability_desc',
                'Den Angreifern immer einen Schritt voraus'
            ),
        },
        {
            title: t('asset_security_title', 'Asset Security Rating'),
            desc: t('asset_security_desc', 'Security Status auf einen Blick'),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title={t('title', 'Vulnerability Management')}
                    text={t(
                        'subtitle',
                        'Überblick bewahren und Schwachstellen effizient behandeln.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/vulnerability.jpg"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Vulnerability Management')} />
            <PageSection size="big">
                <PageSubtitle className="max-w-3xl mt-4">
                    <Trans i18nKey="intro_1">
                        Planung und Durchführung von Security Audits/Penetration
                        Tests sowie Behandlung und Nachhaltung der darin
                        identifizierten Schwachstellen - einfach und
                        unkompliziert.
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="intro_2">
                        Speziell in einer großen IT Landschaft verliert man
                        schnell den Überblick über identifizierte
                        Schwachstellen, die nicht auf der Stelle behoben werden
                        können. Zu oft passiert es, dass diese mit der Zeit in
                        Vergessenheit geraten, aber drastische Auswirkungen auf
                        die Sicherheit einer Organisation haben. Der gesamte
                        Lebenszyklus von Security Audits und der darin
                        identifizierten Schwachstellen kann mit der condignum
                        Plattform gemanaged werden. Von der Planung über die
                        Durchführung von Security Audits bis hin zur Behandlung
                        und Nachhaltung der darin identifizierten
                        Schwachstellen. Und selbst bei der Identifikation
                        vorhandener Schwachstellen unterstützt condignum Sie bei
                        Kombination mit den condignum Security Scan Services.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" />

            <PageSection size="big" id={makeSectionId(overview[0].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="audit_title">
                            Security Audit Management
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="audit_desc">
                            Auditplanung und -Nachhaltung &mdash; jederzeit im Blick
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                    <Trans i18nKey="audit_col1">
                        Security Audits können effizient geplant,
                        gesteuert und nachgehalten werden. Alle Audits
                        der Organisation sowie auch deren Ergebnisse
                        sind jederzeit im Blick und können on-demand
                        visualisiert werden.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'audit_col2_1',
                            'Übersicht über alle Security Audits.'
                        ),
                        t(
                            'audit_col2_2',
                            'Werkzeuge zur einfachen Planung, Steuerung und Nachhaltung von Security Audits.'
                        ),
                        t(
                            'audit_col2_3',
                            'Ergebnisse der Audits sowohl aktuell als auch im zeitlichen Verlauf (Trendanalysen).'
                        ),
                    ]}
                />
            </PageSection>

            <PageSection size="big" id={makeSectionId(overview[1].title)} isDark >
                <header>
                    <PageTitle>
                        <Trans i18nKey="vulnerability_title">
                            Security Vulnerability Management
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="vulnerability_desc">
                            Den Angreifern immer einen Schritt voraus
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                    <Trans i18nKey="vulnerability_col2">
                        Die aktuelle Verwundbarkeit der
                        IT-Landschaft ist jederzeit im Blick. Der aktuelle
                        Status identifizierter Schwachstellen kann
                        problemlos eingesehen werden und die Behandlung wird
                        strukturiert und nachvollziehbar sichergestellt um
                        offene Flanken zu schließen.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'vulnerability_col1_1',
                            'Übersicht über alle identifizierten Security Schwachstellen.'
                        ),
                        t(
                            'vulnerability_col1_2',
                            'Werkzeuge zur strukturierten und nachvollziehbaren Behandlung.'
                        )
                    ]}
                />
            </PageSection>

            <PageSection size="big" id={makeSectionId(overview[2].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="asset_security_title">
                            Asset Security Rating
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="asset_security_desc">
                            Security Status auf einen Blick
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                    <Trans i18nKey="asset_security_col1">
                        Das Security Niveau der Organisationsassets ist
                        stets auf einen Blick ersichtlich und vergleichbar.
                        Sowohl jederzeit aktuell als auch im zeitlichen
                        Verlauf.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="asset_security_col2">
                        Ermöglicht wird dies durch die Berechnung eines
                        einfachen Security Ratings für jedes Asset auf einer
                        Skala von A - E, auf Basis des Umsetzungsstatus der
                        identifizierten Schwachstellen. So wird der
                        Überblick stets bewahrt und dringliche
                        Handlungsfelder werden effizient lokalisiert.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'asset_security_col3_1',
                            'Security Rating auf einer Skala von A - E für einzelne Assets und deren Teilbereiche.'
                        ),
                        t(
                            'asset_security_col3_2',
                            'Security Rating Vergleich zwischen Assets.'
                        )
                    ]}
                />
            </PageSection>
            <ContactSection isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["vulnerability-management"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default VulnerabilityManagement;
